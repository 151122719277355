import React from 'react';
import { MailOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import logoIcon from '../static/logo.png'

export const Banner20DataSource = {
  wrapper: { className: 'banner2' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        page: { className: 'home-page banner2-page' },
        textWrapper: { className: 'banner2-text-wrapper' },
        bg: { className: 'bg bg0' },
        logo: {
          className: 'banner2-logo-img',
          children: logoIcon,
        },
        title: {
          className: 'banner2-title',
          children: 'BitRealm Inc,',
        },
        content: {
          className: 'banner2-content',
          children: 'Dedicated to becoming a global leader infinancial technology (Fin-Tech) andblockchain infrastructure solutions.',
        }
      },
      
    ],
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block',
        xs: 24,
        md: 12,
        className: 'block',
        title: {
          children: (
            <span>
              <span>
                <p>Contact Us</p>
              </span>
            </span>
          ),
          className: 'footer-contact_title_css',
        },
        childWrapper: {
          children: [
            {
              name: 'contact_email',
              className: 'footer-contact_editor_css',
              children: (
                <div>
                  <MailOutlined style={{ fontSize: 14, color: 'white', marginRight: '10px', marginTop: '5px' }} />
                  <a href="mailto:contactus@bitrealm.org">contactus@bitrealm.org</a>
                </div>
              )
            },
            {
              name: 'contact_phone',
              className: 'footer-contact_editor_css',
              children: (
                <div>
                  <ExclamationCircleOutlined style={{ fontSize: 14, color: 'white', marginRight: '10px', marginTop: '5px' }} />
                  <span>To get our address or phone number, <br />please contact our email first. <br />Thank you!</span>
                </div>
              )
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        ©2023 <a href="https://bitrealm.org">BitRealm</a> Inc.
      </span>
    ),
  },
};
