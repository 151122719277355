import featureimg1 from '../static/1.jpg'
import featureimg2 from '../static/2.jpg'
import featureimg3 from '../static/3.jpg'

export const Feature20DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 8, xs: 24 },
  img: {
    children: featureimg2,
  },
  textWrapper: { className: 'content2-text', md: 16, xs: 24 },
  title: { className: 'content2-title', children: 'Financial Technology' },
  content: {
    className: 'content2-content',
    children:
      'With mature development teams in Asia and North America, we are committed to financial technology innovation, aiming to provide the industry with imaginative and creative financial technology services and products. We believe that decentralized finance products are the future of blockchain. Our team is on a mission to continuously explore new frontiers in Fin-Tech innovation.',
  },
};
export const Feature10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 8, xs: 24 },
  img: {
    children: featureimg1,
  },
  textWrapper: { className: 'content1-text', md: 16, xs: 24 },
  title: { className: 'content1-title', children: 'Blockchain Infrastructure' },
  content: {
    className: 'content1-content',
    children:
      'We own a certain scale of blockchain infrastructure, providing computational power to the blockchain industry.In the future, we will offer customers a one-stop, fully-managed blockchain computational power operation solution, featuring competitive electricity pricing, favorable environment, professional operation capabilities, and more.',
  },
};
export const Feature11DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper content1-pb50' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 8, xs: 24 },
  img: {
    children: featureimg3,
  },
  textWrapper: { className: 'content1-text', md: 16, xs: 24 },
  title: { className: 'content1-title', children: 'Green Energy and Localization' },
  content: {
    className: 'content1-content',
    children:
      'We prioritize and strive to be an environmentally responsible company. Our goal is to use 100% green energy in the future, contributing to a better world. Currently, a significant portion of the energy we use is derived from renewable sources such as wind power. We also value our relationships with local communities. We aim to be an integral part of every community where our properties are located, providing employment opportunities, and contributing to the prosperity of these communities.',
  },
};
